<template>
  <svg
    :class="props.class"
    width="7"
    height="4"
    viewBox="0 0 7 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.0325 4L0.00250038 0.251348L6.0625 0.251348L3.0325 4Z" fill="currentColor" />
  </svg>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    class?: string
  }>(),
  {
    class: 'w-4 text-white'
  }
)
</script>
